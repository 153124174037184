import typeToReducer from 'type-to-reducer'
import unionBy from 'lodash/unionBy'
import TYPES from './types'

const initialState = {
  data: [],
  getRewardStatus: {},
  redeemRewardStatus: {},
  getRewardByIdStatus: {},
}

const RewardReducer = typeToReducer(
  {
    [TYPES.GET_REWARD]: {
      PENDING: state => ({
        ...state,
        getRewardStatus: {
          isPending: true,
          error: null,
        },
      }),
      FULFILLED: (state, action) => ({
        ...state,
        data: action.payload,
        getRewardStatus: {
          isFulfilled: true,
          error: null,
        },
      }),
      REJECTED: (state, action) => ({
        ...state,
        getRewardStatus: {
          isRejected: true,
          error: action.payload,
        },
      }),
    },
    [TYPES.REDEEM_REWARD]: {
      PENDING: state => ({
        ...state,
        redeemRewardStatus: {
          isPending: true,
          error: null,
        },
      }),
      FULFILLED: state => ({
        ...state,
        redeemRewardStatus: {
          isFulfilled: true,
          error: null,
        },
      }),
      REJECTED: (state, action) => ({
        ...state,
        redeemRewardStatus: {
          isRejected: true,
          error: action.payload,
        },
      }),
    },
    [TYPES.GET_REWARD_BY_ID]: {
      PENDING: state => ({
        ...state,
        getRewardByIdStatus: {
          isPending: true,
          error: null,
        },
      }),
      FULFILLED: (state, action) => ({
        ...state,
        data: unionBy([action.payload], state.data, 'id'),
        getRewardByIdStatus: {
          isFulfilled: true,
          error: null,
        },
      }),
      REJECTED: (state, action) => ({
        ...state,
        getRewardByIdStatus: {
          isRejected: true,
          error: action.payload,
        },
      }),
    },
  },
  initialState
)

export default RewardReducer
